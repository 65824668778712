import React, { useEffect, useState, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import { Chip } from "@mui/material";

export default function TableVisitor() {
  const [rows, setRows] = useState([]);
  const ws = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket('wss://letmein.axstudio.xyz/ws/visit');

    ws.current.onopen = () => {
      console.log('WebSocket Connected');
    };

    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data);
      if (message.type === 'update') {
        setRows(currentRows => currentRows.map(row => 
          row.id === message.data.id ? {...row, ...message.data} : row
        ));
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket Disconnected');
    };

    const fetchData = async () => {
      const response = await fetch('https://letmein.axstudio.xyz/api/visit');
      const data = await response.json();
      setRows(data);
    };

    fetchData();

    return () => {
      ws.current.close();
    };
  }, []);

  const handleBanUpdate = async (id, isBanned) => {
    // Send message to server via WebSocket
    if (ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ action: 'updateBan', id, isBanned }));
    }
  };

  const columns = [
    {
      field: "default_picture",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        console.log("Image URL:", params.value);
        return <Avatar alt={params.row.visitor_name} src={params.value} />;
      },
    },
    { field: "visitor_name", headerName: "Name", width: 0, flex: 1 },
    { field: "phone_number", headerName: "Phone", width: 0, flex: 1 },
    {
      field: "apartment",
      headerName: "Unit Number",
      width: 200,
      renderCell: (params) =>
        `${params.row.block.replace("Block ", "")} - ${params.row.lvl} - ${
          params.row.unit
        }`,
    },

    { field: "visit_status", headerName: "status", width: 0, flex: 1 },
    { field: "plate_number", headerName: "Plate Number", width: 0, flex: 1 },
    {
      field: "register_date",
      headerName: "Registration Date & Time",
      width: 0,
      type: "Date",
      flex: 2,
    },
    { field: "in_time", type: "Date", headerName: "In", width: 0, flex: 1 },
    { field: "out_time", type: "Date", headerName: "Out", width: 0, flex: 1 },
    {
      field: "registration_type",
      headerName: "Registration Method",
      width: 0,
      flex: 2,
    },
    {
        field: "is_ban",
        align: "center",
        headerAlign: "center",
        headerName: "",
        width: 150,
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={params.value ? "Banned" : "Not Banned"}
            color={params.value ? "error" : "default"}
            onClick={() => handleBanUpdate(params.row.id, !params.value)}
            sx={{
              cursor: "pointer",
              transition: "all ease .5s",
              "&:hover": {
                opacity: 0.5,
                background: "#ffa008",
              },
            }}
          />
        ),
      }
      
  ];

  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <DataGrid
        className="tableVisitorDataGrid"
        rows={rows}
        columns={columns}
        disableDensitySelector
        density={"comfortable"}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
      />
    </div>
  );
}
