import React from "react";
import AccessPointComponent from "../components/accessPointComponent";

function AccessPoint() {
  return (
    <div>
      <AccessPointComponent />
    </div>
  );
}

export default AccessPoint;
