import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

function LoginComponent() {
  const [building, setBuilding] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Separate handlers for each input
  const handleBuildingChange = (event) => {
    setBuilding(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  let navigate = useNavigate();

  function handleClick() {
    navigate('/main/dashboard'); // For going forward
    // navigate(-1); // For going back equivalent to history.goBack()
    // navigate(1); // For going forward equivalent to history.goForward()
  }
  return (
    <div>
      <h2>Welcome Back</h2>
      <p>Please enter your details.</p>

      <label for="building">Building Name or Code</label>
      <input
        id="building"
        type="text"
        value={building}
        placeholder="Building Name or Code"
        onChange={handleBuildingChange} // Set the handler to update the state on every change
      />
      <label for="username">Username</label>
      <input
        id="username"
        type="text"
        value={username}
        placeholder="username"
        onChange={handleUsernameChange} // Set the handler to update the state on every change
      />
      <label for="password">Password</label>
      <input
        id="password"
        type="password"
        value={password}
        placeholder="password"
        onChange={handlePasswordChange} // Set the handler to update the state on every change
      />
      <a href="">Forgot Password?</a>
      <button onClick={handleClick}>Sign In </button>
    </div>
  );
}

export default LoginComponent;
