import React from "react";
import ApartmentList from "../components/apartmentList";

function Apartment() {
    return(
        <>
        <ApartmentList />
        </>
    )
};

export default Apartment;