import React, { useState } from "react";
import Alert from "@mui/material/Alert";


function CheckInForm() {
  const [formData, setFormData] = useState({
    visitor_name: "",
    phone_number: "",
    email: "",
    block: "",
    lvl: "",
    unit: "",
    purpose: "",
    total_visitors: "",
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submissionDate = new Date().toISOString(); 
    const updatedFormData = {
      ...formData,
      register_date: submissionDate,
      registration_type: "On-site WebPortal", 
      default_picture: "default_image_url_here" 

    };
  
    try {
      const response = await fetch("https://letmein.axstudio.xyz/api/visitall", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormData),
      });
      const data = await response.json();
      console.log("Success:", data);
      setAlertMessage("Check-in submitted successfully!");
      setShowAlert(true);
    } catch (error) {
      console.error("error", error);
      setAlertMessage('Failed to submit check-in.');
      setShowAlert(true);
    }
  };

  return (
    <>
      <div className="form">
      {showAlert && <Alert severity="success">{alertMessage}</Alert>}

        <form onSubmit={handleSubmit}>
          <label>
            Visitor Name
            <input
              type="text"
              name="visitor_name"
              required
              placeholder="Visitor Name"
              value={formData.visitor_name}
              onChange={handleChange}
            />
          </label>
          <label>
            Phone Number
            <input
              type="text"
              name="phone_number"
              required
              placeholder="Phone Number"
              value={formData.phone_number}
              onChange={handleChange}
            />
          </label>
          <label>
            Email
            <input
              type="email"
              name="email"
              placeholder="Email (Optional)"
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label>
            Block
            <input
              type="text"
              placeholder="Block"
              required
              name="block"
              value={formData.block}
              onChange={handleChange}
            />
          </label>
          <label>
            Level
            <input
              type="text"
              name="lvl"
              required
              placeholder="Level"
              value={formData.lvl}
              onChange={handleChange}
            />
          </label>
          <label>
            Unit
            <input
              type="text"
              name="unit"
              required
              placeholder="Unit"
              value={formData.unit}
              onChange={handleChange}
            />
          </label>
          <label>
            Visitor Type
            <select
              name="purpose"
              require
              value={formData.purpose}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Please select
              </option>

              <option value="visitor">Visitor</option>
              <option value="drop off">Drop Off</option>
              <option value="pick up">Pick Up</option>
              <option value="contractor">Contractor</option>
              <option value="bulky iten">Bulky Item</option>
              <option value="parcel delivery">Parcel Delivery</option>
              <option value="food delivery">Food Delivery</option>
            </select>
          </label>
          <label>
            Total Visitors
            <input
              type="number"
              name="total_visitors"
              required
              placeholder="Total Visitor"
              value={formData.total_visitors}
              onChange={handleChange}
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
}

export default CheckInForm;
