import Card from "./card";
import CardSection from "./cardSection";
import { BiUser } from "react-icons/bi";
import { BiUserPlus } from "react-icons/bi";
import { BiCar } from "react-icons/bi";
import { BiBell } from "react-icons/bi";
import { BiUserX } from "react-icons/bi";
import { BiMessageAltDetail } from "react-icons/bi";

function Counter() {
  return (
    <>
      <div className="page-item-container">
        <Card>
          <CardSection type="header">Total Visitor</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
            <BiUser />
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">Total Walk-in</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
            <BiUserPlus />
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">Total Vehicle</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
            <BiCar />
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">Vehicle In Premise</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
            <BiCar />
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">Alert</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
            <BiBell />
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">Overstay Visitor</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
            <BiUserX />
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">SMS Count</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
            <BiMessageAltDetail />
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default Counter;
