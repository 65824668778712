import React, { useState } from "react";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Textarea from "@mui/joy/Textarea";

function SendMessageForm() {
  const [formData, setFormData] = useState({
    message_type: "",
    message_template: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="form">
        <label>
          Message Type
          <select
            name="visitor_type"
            require
            value={formData.visitor_type}
            onChange={handleChange}
          >
            <option value="" disabled selected>
              Select type
            </option>

            <option value="visitor">Visitor</option>
            <option value="drop_off">Drop Off</option>
            <option value="pick_up">Pick Up</option>
            <option value="contractor">Contractor</option>
            <option value="bulky_iten">Bulky Item</option>
            <option value="parcel_delivery">Parcel Delivery</option>
            <option value="food_delivery">Food Delivery</option>
          </select>
          <select
            name="visitor_type"
            require
            value={formData.visitor_type}
            onChange={handleChange}
          >
            <option value="" disabled selected>
              Select template
            </option>

            <option value="visitor">Visitor</option>
            <option value="drop_off">Drop Off</option>
            <option value="pick_up">Pick Up</option>
            <option value="contractor">Contractor</option>
            <option value="bulky_iten">Bulky Item</option>
            <option value="parcel_delivery">Parcel Delivery</option>
            <option value="food_delivery">Food Delivery</option>
          </select>
        </label>
        <label className="sendtolabel">
          Send to
          <RadioGroup defaultValue="outlined" name="radio-buttons-group">
            <Radio value="outlined" label="Visitors" variant="outlined" />
            <Radio value="soft" label="Residents" variant="outlined" />
          </RadioGroup>
        </label>
        <label>
          Message
          <Textarea size="sm" minRows={5} name="Size" placeholder="Write your message" />
        </label>
      </div>
    </>
  );
}

export default SendMessageForm;
