import Card from "./card";
import CardSection from "./cardSection";
import TableInvitations from "./tableInvitations";

function InviteTableComponent() {
  return (
    <>
      <div className="page-item-container">
        <Card className="">
          <CardSection type="header">Invitation</CardSection>
          <CardSection type="inviteTable">
            <TableInvitations />
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default InviteTableComponent;
