import React from "react";
import Card from "./card";
import CardSection from "./cardSection";
import TableRequest from "./tableRequest";

function VisitRequestComponent() {
    return(
        <>
        <div className="page-item-container">
            <Card>
                <CardSection type="header">Visit Request</CardSection>
                <CardSection type="requestTable">
                    <TableRequest />
                </CardSection>
            </Card>
            
        </div>
        </>
    )
};

export default VisitRequestComponent;