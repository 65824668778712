import { NavLink } from "react-router-dom";
import {
  BiLayer,
  BiLineChart,
  BiHomeAlt,
  BiUserCircle,
  BiUserCheck,
  BiUserPin,
  BiVector,
  BiUserPlus,
  BiUserVoice,
  BiSlider,
  BiBroadcast,
  BiMapPin,
  BiCommentError,
  BiUser,
} from "react-icons/bi";

const Menu = () => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink
            to="/main/dashboard"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiLayer />
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/analytics"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiLineChart />
            Analytics
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/apartments"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiHomeAlt />
            Apartments
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/residents"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiUserCircle />
            Residents
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/visit"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiUserCheck />
            Visit
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/user"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiUserPin />
            User
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/activity"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiVector />
            Activity
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/visit-request"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiUserPlus />
            Visit Request
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/invitation"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiUserVoice />
            Invitation
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/main/access-points"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiBroadcast />
            Access Points
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/destination"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiMapPin />
            Destination
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/main/privacy"
            className={({ isActive }) => (isActive ? "navActive" : undefined)}
          >
            <BiCommentError />
            Privacy
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
