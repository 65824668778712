import React from "react";
import FilterAnalytics from "../components/filterAnalytics";
import Card from "../components/card";
import CardSection from "../components/cardSection";
import MostVisitorChart from "../components/mostVisitorChart";
import BasicPie1 from "../components/pieChart1";
import BasicPie2 from "../components/pieChart2";
import BasicPie3 from "../components/pieChart3";
import StackChart1 from "../components/stackChart1";
import StackChart2 from "../components/stackChart1";


function Analytics() {
  return (
    <>
      <FilterAnalytics />
      <div className="page-item-container">
        <Card>
          <CardSection type="header">10 Units With Highest Visitor</CardSection>
          <CardSection type="itemChart">
            <MostVisitorChart />
          </CardSection>
        </Card>
      </div>
      <div className="page-item-container">
        <Card>
          <CardSection type="header">Registration</CardSection>
          <CardSection type="itemChart">
            <div className="chartCompartment">
              <BasicPie1 />
            </div>
            <div className="chartCompartment">
              <BasicPie2 />
            </div>
            <div className="chartCompartment">
              <BasicPie3 />
            </div>
          </CardSection>
        </Card>
      </div>
      <div className="page-item-container">
        <Card>
          <CardSection type="itemChart">
            <div className="chartCompartment">
              <StackChart1 />
            </div>
            <div className="chartCompartment">
              <StackChart2 />
            </div>

          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default Analytics;
