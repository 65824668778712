import React from "react";
import InvitationCounterComponent from "../components/invitationCounterComponent";
import InviteTableComponent from "../components/inviteTableComponent";


function Invitations() {
  return (
    <>
      <InvitationCounterComponent />
      <InviteTableComponent />

    </>
  );
}

export default Invitations;
