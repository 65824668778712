import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Chip, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const columns = [
  { field: "name", headerName: "Access Point Name", flex: 1 },
  { field: "code", headerName: "Access Point Code", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 4,
    renderCell: (params) => (
      <Chip
        label={params.value}
        color={params.value === "Active" ? "primary" : "default"}
      />
    ),
  },
  {
    field: "edit",
    headerName: "",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <Button className="magicBtn" startIcon={<EditNoteIcon />}>
        Edit
      </Button>
    ),
  },
  {
    field: "delete",
    headerName: "",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <Button className="magicBtn" startIcon={<DeleteForeverIcon />}>
        Delete
      </Button>
    ),
  },
];

const rows = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  name: `Gate ${String(index + 1).padStart(3, "0")}`,
  code: String(index + 1).padStart(3, "0"),
  status: index % 2 === 0 ? "Active" : "Inactive",
}));

const CustomToolbar = () => {
  return (
    <Box
      sx={{
        p: 1,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <GridToolbar />
      <Button variant="contained" color="primary" startIcon={<AddIcon />}>
        Add
      </Button>
    </Box>
  );
};

const AccessPointsTable = () => {
  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableDensitySelector
        density={"comfortable"}
        components={{
          Toolbar: CustomToolbar,
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
      />
    </div>
  );
};

export default AccessPointsTable;
