import React, { useEffect, useState, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import { Chip } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Button from "@mui/material/Button";

export default function TableUser() {
  const [rows, setRows] = useState([]);
  const ws = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket('wss://letmein.axstudio.xyz/ws/all-user');

    ws.current.onopen = () => {
      console.log('WebSocket Connected');
    };

    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data);
      if (message.type === 'update') {
        setRows(currentRows => currentRows.map(row => 
          row.resident_id === message.data.resident_id ? {...row, ...message.data} : row
        ));
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket Disconnected');
    };

    const fetchData = async () => {
      const response = await fetch('https://letmein.axstudio.xyz/api/all-user');
      const data = await response.json();
      setRows(data);
    };

    fetchData();

    return () => {
      ws.current.close();
    };
  }, []);

  const handleUpdate = async (resident_id, isUpdate) => {
    // Send message to server via WebSocket
    if (ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ action: 'updateStatus', resident_id, isUpdate }));
    }
  };

  const columns = [
    {
      field: "default_picture",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        console.log("Image URL:", params.value);
        return <Avatar alt={params.row.username} src={params.value} />;
      },
    },
    { field: "username", headerName: "Username", width: 0, flex: 1 },

    { field: "resident_name", headerName: "Name", width: 0, flex: 1 },
    { field: "email", headerName: "Email", width: 0, flex: 1 },

    { field: "phone_number", headerName: "Phone", width: 0, flex: 1 },
    { field: "user_type", headerName: "User Type", width: 0, flex: 1 },




    {
        field: "status1",
        align: "center",
        headerAlign: "center",
        headerName: "",
        width: 150,
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={params.value ? "Active" : "Inactive"}
            color={params.value ? "primary" : "default"}
            onClick={() => handleUpdate(params.row.resident_id, !params.value)}
            sx={{
              cursor: "pointer",
              transition: "all ease .5s",
              "&:hover": {
                opacity: 0.5,
                background: "#3383de",
              },
            }}
          />
        ),
      },
      {
        field: "is_action",
        headerName: "",
        width: 0,
        flex: 3,
        align: "right",
        renderCell: (params) =>
          params.value ? (
            <Button className="magicBtn2" startIcon={<EditNoteIcon />}>Edit</Button>
          ) : null,
      },
      
  ];

  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <DataGrid
        className="tableVisitorDataGrid"
        rows={rows}
        getRowId={(row) => row.resident_id}

        columns={columns}
        disableDensitySelector
        density={"comfortable"}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
      />
    </div>
  );
}
