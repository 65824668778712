import React from "react";
import Activity2 from "../components/activity2";

function ActivityPage() {
  return (
    <>
      <Activity2 />
    </>
  );
}

export default ActivityPage;
