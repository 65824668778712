import Card from "./card";
import CardSection from "./cardSection";
import TableActivity2 from "./tableActivity2";

function Activity2() {
  return (
    <>
      <div className="page-item-container">
        <Card>
          <CardSection type="header">Activity</CardSection>
          <CardSection type="activityTable">
            <TableActivity2 />
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default Activity2;
