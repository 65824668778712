import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const generateRows = () => {
  const rows = [];
  let id = 1;
  ['3A', '3B'].forEach((block) => {
    for (let level = 1; level <= 11; level++) {
      for (let unit = 1; unit <= 20; unit++) {
        rows.push({
          id: id++,
          unit: `Block ${block}, Level ${level}, Unit ${unit}`,
        });
      }
    }
  });
  return rows;
};

const columns = [
  {
    field: "unit",
    headerName: "",
    flex: 1,
    renderHeader: () => null, // Hide the header
  },
];

const rows = generateRows();

const TableDestinationList = () => {
  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        disableColumnMenu
        disableColumnSelector
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        showCellRightBorder
        showColumnRightBorder
        slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
        components={{
          ColumnHeaders: () => null, // Hide column headers
        }}
      />
    </div>
  );
};

export default TableDestinationList;
