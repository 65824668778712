import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useFetchData2 from "../hook/useFetchData2";
import Avatar from "@mui/material/Avatar";

export default function TableActivity() {
  const { data,  } = useFetchData2("https://letmein.axstudio.xyz/api/visit", {
    rowLength: 100,
  });

  const columns = [
    {
      field: "default_picture",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        console.log("Image URL:", params.value);
        return (
          <Avatar
            alt={params.row.visitor_name}
            src={params.value}
          />
        );
      },
    },
    { field: "visitor_name", headerName: "Name", width: 0, flex: 1 },
    { field: "purpose", headerName: "Purpose", width: 0, flex: 1 },
    { field: "registration_type", headerName: "Registration Type", width: 0, flex: 1},
    { field: "phone_number", headerName: "Phone", width: 0, flex: 1 },
    { field: "plate_number", headerName: "Plate Number", width: 0, flex: 1 },
    {
      field: "register_date",
      headerName: "Registration Date & Time",
      width: 0, flex: 1
    },
    { field: "in_time", headerName: "In", width: 0, flex: 1 },
    { field: "out_time", headerName: "Out", width: 0, flex: 1 },
    { field: "visit_status", headerName: "Status", width: 0, flex: 1 },
  ];

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        disableColumnFilter
        disableDensitySelector
        density={"comfortable"}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
}


