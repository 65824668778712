import React from 'react';
import Counter from '../components/counter';
import DashboardChart from '../components/dashboardChart';
import Activity from '../components/activity';

function Dashboard() {
  return (
    <div>
      <Counter />
      <DashboardChart />
      <Activity />
      
      
    </div>
  );
};

export default Dashboard;
