import React from "react";
import Card from "./card";
import CardSection from "./cardSection";
import AccessPointsTable from "./tableAccessPoint";

function AccessPointComponent() {
  return (
    <>
      <div className="page-item-container">
        <Card className="">
          <CardSection type="header">Access Point</CardSection>
          <CardSection type="inviteTable">
            <AccessPointsTable />
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default AccessPointComponent;
