import Card from "./card";
import CardSection from "./cardSection";
import TableActivity from "./tableActivity";

function Activity() {
  return (
    <>
      <div className="page-item-container">
        <Card>
          <CardSection type="header">Activity</CardSection>
          <CardSection type="activityTable">
            <TableActivity />
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default Activity;
