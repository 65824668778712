import React from "react";
import Card from "./card";
import CardSection from "./cardSection";
import TableUser from "./tableUser";

function UserComponents() {
  return (
    <>
      <div className="page-item-container">
        <Card className="">
          <CardSection type="header">User List</CardSection>
          <CardSection type="inviteTable"> 
            <TableUser />
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default UserComponents;
