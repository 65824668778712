import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const visitorTypes = [
  "Drop Off", "Parcel Delivery", "Visitor", "Small Furniture Delivery", 
  "Pick Up", "Delivery", "Contractor", "Bulky Items Delivery", 
  "Food Delivery", "Resident"
];

const accessPoints = Array.from({ length: 20 }, (_, index) => `Gate ${String(index + 1).padStart(3, "0")}`);

const generateRows = () => {
  const rows = [];
  let id = 1;
  visitorTypes.forEach((type) => {
    accessPoints.forEach((point) => {
      rows.push({
        id: id++,
        visitorType: type,
        accessPoint: point,
      });
    });
  });
  return rows;
};

const columns = [
  { field: "visitorType", headerName: "Visitor Type", flex: 1 },
  { field: "accessPoint", headerName: "Access Point", flex: 1 },
  {
    field: "add",
    headerName: "",
    sortable: false,
    renderCell: () => (
      <IconButton color="primary">
        <AddIcon />
      </IconButton>
    ),
  },
];

const rows = visitorTypes.map((type, index) => ({
  id: index + 1,
  visitorType: type,
  accessPoint: accessPoints[index % accessPoints.length],
}));

const TableAccessPointList = () => {
  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableColumnMenu
        disableColumnSelector
        showCellRightBorder
        showColumnRightBorder
        disableDensitySelector
        density={"comfortable"}
        hideFooter// Disable pagination



      
      />
    </div>
  );
};

export default TableAccessPointList;
