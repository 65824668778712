import React from "react"; 
import VisitRequestComponent from "../components/visitRequestComponent";

function VisitRequest() {
    return(
        <>
        <VisitRequestComponent />
        </>
    )
};

export default VisitRequest;