import Card from "./card";
import CardSection from "./cardSection";
import TableResident from "./tableResidents";

function ResidentList() {
  return (
    <>
      <div className="page-item-container">
        <Card>
          <CardSection type="header">Resident List</CardSection>
          <CardSection type="residentTable">
            <TableResident />
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default ResidentList;
