import React, { useEffect, useState, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddTaskIcon from "@mui/icons-material/AddTask";

export default function TableRequest() {
  const [rows, setRows] = useState([]);
  const ws = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket("wss://letmein.axstudio.xyz/ws/visit-request");

    ws.current.onopen = () => {
      console.log("WebSocket Connected");
    };

    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data);
      if (message.type === "update") {
        setRows((currentRows) =>
          currentRows.map((row) =>
            row.id === message.data.id ? { ...row, ...message.data } : row
          )
        );
      } else if (message.type === "delete") {
        setRows((currentRows) =>
          currentRows.filter((row) => row.id !== message.data.id)
        );
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket Disconnected");
    };

    const fetchData = async () => {
      const response = await fetch("https://letmein.axstudio.xyz/api/visit-request");
      const data = await response.json();
      setRows(data);
    };

    fetchData();

    return () => {
      ws.current.close();
    };
  }, []);

  const handleApproval = async (id) => {
    try {
      const response = await fetch(
        `https://letmein.axstudio.xyz/api/visit-request/${id}`,
        {
          method: "PUT",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ approvalStatus: true })
        }
      );
      const result = await response.json();
      console.log("Updated:", result);
      // Update the rows by filtering out the updated row
      setRows((currentRows) => 
        currentRows.filter(row => row.id !== id)
      );
    } catch (error) {
      console.error("Error updating entry:", error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://letmein.axstudio.xyz/api/visit-request/${id}`,
        {
          method: "DELETE",
        }
      );
      const result = await response.json();
      console.log("Deleted:", result);
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };

  const columns = [
    {
      field: "default_picture",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        return <Avatar alt={params.row.visitor_name} src={params.value} />;
      },
    },
    { field: "visitor_name", headerName: "Name", width: 0, flex: 1 },
    { field: "phone_number", headerName: "Phone", width: 0, flex: 1 },
    {
      field: "apartment",
      headerName: "Destination",
      width: 200,
      renderCell: (params) =>
        `${params.row.block.replace("Block ", "")} - ${params.row.lvl} - ${
          params.row.unit
        }`,
    },
    { field: "plate_number", headerName: "Plate Number", width: 0, flex: 1 },
    {
      field: "register_date",
      headerName: "Registration Date & Time",
      width: 0,
      flex: 2,
    },
    {
      field: "registration_type",
      headerName: "Registration Method",
      width: 0,
      flex: 2,
    },
    {
      field: "aproval",
      headerName: "Approval",
      width: 0,
      flex: 1,
      align: "center",
      renderCell: (params) =>
        !params.value ? (
          <Button
            className="magicBtn"
            startIcon={<AddTaskIcon />}
            onClick={() => handleApproval(params.row.id)}
          >
            Approve
          </Button>
        ) : null,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 0,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Button
          className="magicBtn"
          startIcon={<HighlightOffIcon />}
          onClick={() => handleDelete(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <DataGrid
        className="tableVisitorDataGrid"
        rows={rows}
        columns={columns}
        disableDensitySelector
        density={"comfortable"}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
      />
    </div>
  );
}
