import React from "react";
import Card from "./card";
import CardSection from "./cardSection";
import { BiX } from "react-icons/bi";

const Popup = ({ isOpen, onClose, children, headerPopup }) => {
  if (!isOpen) return null;

  return (
    <>
      <Card className='popupContainer'>
        <CardSection type="popupHeader">
          {headerPopup}
          <BiX onClick={onClose} />
        </CardSection>
        <CardSection type="itemPopup">{children}</CardSection>
      </Card>
    </>
  );
};


export default Popup;