import React from "react";
import UserComponents from "../components/userComponents";

function User() {
  return (
    <>
      <UserComponents />
    </>
  );
}

export default User;
