import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

export default function StackChart1() {
  return (
    <>
    <h2>Average Vehicle</h2>
      <BarChart
        series={[
          { data: [3], stack: "A", label: "Series A1" },
          { data: [4], stack: "A", label: "Series B1" },
          { data: [2], stack: "A", label: "Series B2" },
          { data: [10], stack: "A", label: "Series C1" },
        ]}
        layout="horizontal"
        height={350}
      />
    </>
  );
}
