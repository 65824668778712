import React from "react";
import logoHorizontal from "./asset/logowhite1.png";
import imgLogin from "./asset/imgLogin1.png";
import LoginComponent from "./components/loginComponent";

function Login() {
  return (
    <div className="login-container">
      <div className="login-left-sections">
        <div className="login-page-elements">
          <img src={logoHorizontal} alt="" />
          <p>
            <b>Visitor management system</b> helps you improve building safety and
            employee safety.
          </p>

          <div className="img-login">
            <img src={imgLogin} alt="" />
          </div>
        </div>
      </div>
      <div className="login-right-sections">
        <div className="login-component-elements">
          <LoginComponent />
        </div>
      </div>
    </div>
  );
}

export default Login;
