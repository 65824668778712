import BottomMenu from "../components/bottomMenu";
import React from "react";
import Menu from "../components/menu";
import logoMain from "../asset/logoMain.png";

// Import other components

const NavMenu = () => {
  return (
    <div className="nav-menu">
      <div className="logoNav">
        <img src={logoMain} alt="" />
      </div>
      <div className="nav-menu-top">
        <h2>All Menu</h2>
        <Menu />
      </div>
      <div className="nav-menu-bottom">
        <BottomMenu />
      </div>
    </div>
  );
};

export default NavMenu;
