import React from "react";
import Card from "./card";
import CardSection from "./cardSection";
import Checkbox from "@mui/joy/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";

function FilterAnalytics() {
  return (
    <>
      <div className="page-item-container">
        <Card>
          <CardSection type="header">Filter Analytics</CardSection>
          <CardSection type="itemFilter">
            <div className="filterAnalyticsRightSection">
              <div className="datePickerContainer">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label="Start Date" className="datePicker" />
                  <DatePicker label="End Date" className="datePicker" />
                </LocalizationProvider>
              </div>
              <div className="checkboxContainer">
                <Checkbox
                  label="Exclude public holidays
"
                />
                <Checkbox
                  label="Exclude public holidays Eve
"
                />
              </div>
            </div>
            <div className="filterAnalyticsLeftSection">
              <Button startIcon={<RotateLeftIcon />} className="analyticsBtn">
                Reset
              </Button>
              <Button startIcon={<SaveAltIcon />} className="analyticsBtn">
                Export
              </Button>
            </div>
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default FilterAnalytics;
