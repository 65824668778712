import { BiQrScan } from "react-icons/bi";
import { BiNote } from "react-icons/bi";
import { BiNotepad } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";
import Avatar from "@mui/material/Avatar";
import shelby from "../asset/shelby.jpg";

const UserHeader = ({ openPopup }) => {
  return (
    <div className="user-header-container">
      <div className="actionHeader">
        <BiQrScan onClick={() => openPopup('popup1')} />
        <BiNote onClick={() => openPopup('popup2')} />
        <BiNotepad onClick={() => openPopup('popup3')} />
        <BiMailSend onClick={() => openPopup('popup4')} />
      </div>
      <div className="userSection">
        <div className="userSectionText">
          <h3>Thomas Shelby</h3>
          <p>2024-06-09 | 19:07:17</p>
        </div>
        <div className="defaultPicture">
          <Avatar className="avatarHeader" alt="shelby" src={shelby} />
        </div>
      </div>
    </div>
  );
}

export default UserHeader;
