import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useFetchData from "../hook/useFetchData2";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Chip } from "@mui/material";

export default function TableActivity() {
  const { data, loading } = useFetchData("https://letmein.axstudio.xyz/api/residents3");

  const getStatusChip = (status) => {
    const chipColor = status === "Active" ? "primary" : "default";
    return <Chip label={status} color={chipColor} />;
  };

  const columns = [
    {
      field: "default_picture",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        console.log("Image URL:", params.value);
        return <Avatar alt={params.row.resident_name} src={params.value} />;
      },
    },
    { field: "resident_name", headerName: "Name", width: 0, flex: 1 },
    { field: "username", headerName: "Username", width: 0, flex: 1 },
    { field: "email", headerName: "Email", width: 0, flex: 2 },

    {
      field: "apartment",
      headerName: "Unit Number",
      width: 0,
      align: "center",
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) =>
        `${params.row.block.replace("Block ", "")} - ${params.row.lvl} - ${
          params.row.unit
        }`,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: 'center',


      renderCell: (params) => getStatusChip(params.value),
    },
    {
      field: "is_res_edit",
      headerName: "",
      width: 0,
      flex: 3,
      align: "right",

      renderCell: (params) => {
        return params.value ? (
          <Button className="magicBtn" startIcon={<EditNoteIcon />}>
            Edit
          </Button>
        ) : null;
      },
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <DataGrid
        rows={data}
        getRowId={(row) => row.resident_id}
        columns={columns}
        disableColumnFilter
        disableDensitySelector
        density={"comfortable"}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
}
