import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useFetchData from "../hook/useFetchData2";
import Avatar from "@mui/material/Avatar";
import Switch from "@mui/material/Switch";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Button from "@mui/material/Button";
import AvatarGroup from "@mui/material/AvatarGroup";

export default function TableApartment() {
  const { data, loading } = useFetchData(
    "https://letmein.axstudio.xyz/api/residents2"
  );

  const columns = [
    {
      field: "apartment",
      headerName: "Unit Number",
      width: 200,
      renderCell: (params) =>
        `${params.row.block.replace("Block ", "")} - ${params.row.lvl} - ${
          params.row.unit
        }`,
    },
    {
      field: "is_office",
      headerName: "Office",
      width: 0,
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          inputProps={{ "aria-label": "controlled" }}
          color="primary"
        />
      ),
    },
    {
      field: "is_auto_approve",
      headerName: "Approval",
      width: 0,
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          inputProps={{ "aria-label": "controlled" }}
          color="primary"
        />
      ),
    },
    {
      field: "is_non_residential",
      headerName: "Non Residential",
      width: 0,
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          inputProps={{ "aria-label": "controlled" }}
          color="primary"
        />
      ),
    },
    {
      field: "bazookas",
      headerName: "Residents",
      width: 0,

      renderCell: (params) => (
        <AvatarGroup max={4}>
          {params.value.map((bazooka, index) => (
            <Avatar
              key={index}
              alt={bazooka.name || "Resident"}
              src={bazooka.imageUrl || "default_image_url_here"}
            />
          ))}
        </AvatarGroup>
      ),
    },
    {
      field: "is_edit",
      headerName: "",
      width: 0,
      flex: 3,
      align: "right",
      renderCell: (params) =>
        params.value ? (
          <Button className="magicBtn" startIcon={<EditNoteIcon />}>
            Edit
          </Button>
        ) : null,
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }


  console.log(data); // Add this line to check data

  return (
    <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
      <DataGrid
        className="datagridTable"
        rows={data}
        getRowId={(row) => row.apartment_id}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        density={"comfortable"}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
      />
    </div>
  );
}
