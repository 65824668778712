import React from "react";
import VisitorComponent from "../components/visitor";

function Visit() {
    return(
        <>
        <VisitorComponent />
        </>
    )
};

export default Visit;