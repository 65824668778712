import UserHeader from "./userHeader";
import React, { useState } from "react";

import Popup from "../components/popup";
import CheckInForm from "./checkinForm";
import InviteForm from "./inviteForm";
import SendMessageForm from "./sendMessageForm";

const Header = () => {
  const [activePopup, setActivePopup] = useState(null); // null means no modal is active

  const openPopup = (popupId) => setActivePopup(popupId);
  const closePopup = () => setActivePopup(null);
  return (
    <>
      <div className="header-container">
        <div className="project-site-container">
          <h2>
            Welcome to <b>Edifice</b>!
          </h2>
        </div>
        <UserHeader openPopup={openPopup} />
      </div>
      <Popup
        isOpen={activePopup === "popup1"}
        onClose={closePopup}
        headerPopup={<h2>Scan QR</h2>}
        children={<input placeholder="Scan QR Code" type="text" />}
      />
            <Popup
        isOpen={activePopup === "popup2"}
        onClose={closePopup}
        headerPopup={<h2>Check-in Details</h2>}
        children={<CheckInForm />}
      />
            <Popup
        isOpen={activePopup === "popup3"}
        onClose={closePopup}
        headerPopup={<h2>Create Invites</h2>}
        children={<InviteForm />}
      />
            <Popup
        isOpen={activePopup === "popup4"}
        onClose={closePopup}
        headerPopup={<h2>Send A Message</h2>}
        children={<SendMessageForm />}
      />
    </>
  );
};

export default Header;
