import React from "react";
import Card from "./card";
import CardSection from "./cardSection";
import TableVisitor from "./tableVisitor";

function VisitorComponent() {
  return (
    <>
      <div className="page-item-container">
        <Card>
          <CardSection type="header">Visit</CardSection>
          <CardSection type="visitorTable">
            <TableVisitor />
          </CardSection>
        </Card>
      </div>
    </>
  );
}

export default VisitorComponent;
