import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import NavMenu from "./components/navMenu";
import Header from "./components/header";
import Analytics from "./pages/analytics";
import Apartment from "./pages/apartment";
import Residents from "./pages/residents";
import ActivityPage from "./pages/activity";
import Visit from "./pages/visit";
import VisitRequest from "./pages/visitRequest";
import Invitations from "./pages/invitation";
import User from "./pages/user";
import AccessPoint from "./pages/accessPoints";
import Destination from "./pages/destination";




// Import other components

const Main = () => {
  return (
    <div className="content">
      <NavMenu />
      <section>
        <Header />

        <div className="page-container">
          <div className="page-content">
            <Routes>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="apartments" element={<Apartment />} />
              <Route path="residents" element={<Residents />} />
              <Route path="activity" element={<ActivityPage />} />
              <Route path="visit" element={<Visit />} />
              <Route path="visit-request" element={<VisitRequest />} />
              <Route path="invitation" element={<Invitations />} />
              <Route path="user" element={<User />} />
              <Route path="access-points" element={<AccessPoint />} />
              <Route path="destination" element={<Destination />} />






            </Routes>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Main;
