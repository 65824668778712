import React from 'react';

const CardSection = ({ children, type = 'item' }) => {
  const className = `card-section ${type}`;
  return (
    <div className={className}>
      {type === 'header' ? <h2>{children}</h2> : children}
    </div>
  );
};

export default CardSection;