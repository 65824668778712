import React from "react";
import ResidentList from "../components/residentsList";

function Residents() {
    return(
        <>
        <ResidentList />
        </>
    )
};

export default Residents;