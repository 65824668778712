import React from "react";
import Card from "./card";
import CardSection from "./cardSection";



function InvitationCounterComponent() {
    return(

        <>
      <div className="page-item-container">
       
        <Card>
          <CardSection type="header">Today</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">Tomorrow</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">Next 7 Days</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">All Invites</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
          </CardSection>
        </Card>
        <Card>
          <CardSection type="header">Expired</CardSection>
          <CardSection type="itemCounter">
            <h1>0</h1>
          </CardSection>
        </Card>
      </div>
        </>
    )
};

export default InvitationCounterComponent;