import React from "react";
import TableDestinationList from "../components/tableDestinationList";
import Card from "../components/card";
import CardSection from "../components/cardSection";
import TableAccessPointList from "../components/tableAccessPointList";

function Destination() {
  return (
    <div className="page-item-container">
      <div className="newContainer">
        <div className="sectionLeft">
          <Card>
            <CardSection type="header">Destination</CardSection>
            <CardSection>
              <TableDestinationList />
            </CardSection>
          </Card>
        </div>
        <div className="sectionRight">
          <Card>
            <CardSection type="header">Assign Access Point</CardSection>

            <CardSection type="">
              <TableAccessPointList />
            </CardSection>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Destination;
